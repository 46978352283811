







import {Component, Vue} from 'vue-property-decorator'
import CreateProposalDocumentViewer from '@/components/proposal/modals/CreateProposalDocumentViewer.vue'

@Component({
  components: {
    CreateProposalDocumentViewer
  }
})
export default class UI extends Vue {

  private open: boolean = false

}

